import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

// firebase init = add your own config here
const firebaseConfig = {
	apiKey: 'AIzaSyBp7ikVmR6M0mUe3ms8UIPXup1QelaaaK8',
	authDomain: 'quickleads-cms---admin.firebaseapp.com',
	databaseURL: '',
	projectId: 'quickleads-cms---admin',
	storageBucket: 'quickleads-cms---admin.appspot.com',
	messagingSenderId: '325907085618',
	appId: '1:325907085618:web:774dc62c60bd99feb8bf2b'
}

firebase.default.initializeApp(firebaseConfig)

// utils
const db = firebase.default.firestore()
const auth = firebase.default.auth()
const storage = firebase.default.storage()

// collection references
const usersCollection = db.collection('users');
const websitesCollection = db.collection('websites');
const pagesCollection = db.collection('pages');
const formsCollection = db.collection('forms');

export {
	db,
	auth,
	storage,
	usersCollection,
	websitesCollection,
	pagesCollection,
	formsCollection
}
